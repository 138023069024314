.head-section .head-sec .country-code-drop button {
  background: transparent;
  color: black;
  border: none;
  outline: none;
}
.head-section .head-sec .country-code-drop .btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  background: transparent;
  color: black;
  border: none;
  outline: none;
}
.head-section .head-sec .content-section p {
  color: #fff;
  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35); */
}
/* .head-section .head-sec .content-section p.no-baggage {
  font-weight: 600;
} */

.head-section .head-sec .content-section .desc span {
  color: #445e64;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.head-section .head-sec .view-all-properties button {
  margin-top: 100px;
  padding: 16px 20px;
  border-radius: 7px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.head-section .head-sec .flutterpads-logo {
  margin-top: 80px;
  margin-bottom: 50px;
}
.head-section .head-sec .carousal-section .carousal-bg {
  background: url(../images/landingPage/headCarousal.png);
  height: 850px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.head-section .head-sec .carousal-signin-sec {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 27px -11px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(9.5px);
  width: 657px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40px;
  z-index: 3;
}
.head-section .head-sec .carousal-signin-sec button,
.head-section .head-sec .carousal-signin-sec .dropdown {
  background-color: transparent;
  border: none;
  outline: none;
  color: #3e3e3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.head-section .head-sec .caption-location-sec {
  background: rgba(24, 24, 24, 0.2);
  width: 255px;
  height: 34px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.head-section .head-sec .caption-location-sec img {
  width: 25px;
  height: 25px;
}
.head-section .head-sec .caption-location-sec .location-name {
  border-right: 1px solid white;
}

.head-section .head-sec .carousal-section .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 6.25rem;
  left: 21%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.head-section .head-sec .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 7.35rem;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: end;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.head-section .head-sec .carousel-indicators [data-bs-target] {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: none;
  outline: 1px solid White;
  outline-offset: 2px;
  margin-right: 6px;
}

/*Filter section*/
.filter {
  background-color: white !important;
  padding: 50px 0px;
}

.filter .filter-section {
  /* background: linear-gradient(180deg, #f8c943 0%, #f6cb57 100%);
  min-height: 200px;
  padding: 20px 0px; */
  /* background-color: white !important; */
}
.filter .filter-section .stay-rental-exp {
  /* height: 83px; */
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px 12px 0px 0px;
  background: #fff;
}
.filter .filter-section .stay-rental-exp .stay:hover {
  border-bottom: 2px solid var(--dark-blue);
}
.filter .filter-section .stay-rental-exp .stay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* padding: 6px 20px; */
  padding: 7px 20px;
  border-bottom: 2px solid transparent;
  /* margin-right: 10px; */
}

.filter .filter-section .stay p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.filter .filter-section .location input,
.filter .filter-section .departure-sec input {
  background: transparent;
  border: none;
  outline: none;
  width: 100px;
}
.filter .filter-section .filter-all-categories {
  /* height: 50px; */
  /* background: rgba(255, 255, 255, 0.8); */
  border-radius: 0px 12px 12px 12px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 7px 0px;
  flex-wrap: wrap;
}
.filter .filter-section .search-btn button {
  border-radius: 0px;
  border-radius: 12px;
  font-size: 16px;
  padding: 8px 20px;
  margin-right: 15px;
  margin-left: 15px;
  font-weight: 600;
}

.filter .where_to_sec h3 {
  color: #000;
  font-size: 45px;
  font-weight: 700;
  font-family: Inter;
}
.filter .where_to_sec p {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  font-family: Inter;
}

.filter .where_to_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-container-filter {
  max-width: 90%;
}

@media (min-width: 1400px) and (max-width: 1800px) {
  .head-section .head-sec .content-section .desc span {
    font-size: 17px;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  /* .head-section .head-sec .view-all-properties button {
    margin-top: 70px;
    margin-bottom: 30px;
  } */
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .head-section .head-sec .content-section .desc span {
    font-size: 15px;
  }
  .filter .filter-section .departure-sec input {
    width: 100px !important;
  }
}

@media (min-width: 1801px) {
  .nav_header_ryt_sec .dropdown-toggle::after {
    vertical-align: unset !important;
    border-top: 0.6em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }
}
@media (max-width: 850px) {
  .filter .filter-section .filter-all-categories {
    /* overflow-x: auto; */
  }
}
@media (max-width: 380px) {
  .filter .filter-section .stay img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .filter .filter-section .stay p {
    font-size: 10px;
  }
}
@media (min-width: 1200px) {
  .head-section .head-sec .left-side-content-section {
    /* padding-left: 110px; */
  }
}
@media (max-width: 1200px) {
  /* .first-head-sec {
    display: flex;
    justify-content: center;
  } */
  .management_details .custom-row {
    margin-bottom: 40px !important;
  }
}
@media (max-width: 767px) {
  .head-section .head-sec .left-side-content-section {
    /* padding: 0px 20px; */
  }
  .head-section .head-sec .flutterpads-logo {
    margin-top: 26px;
    margin-bottom: 36px;
  }
  .custom-container-filter {
    max-width: 100%;
  }
  .management_details .profile-logo {
    height: 100% !important;
  }
  .management_details .profile-logo img {
    object-fit: cover !important;
  }
  .management_details .profile-details {
    height: unset !important;
  }
}

@media (min-width: 381px) and (max-width: 567px) {
  .head-section .head-sec .carousal-signin-sec button,
  .head-section .head-sec .carousal-signin-sec .dropdown {
    font-size: 14px;
  }
  .head-section .head-sec .carousal-signin-sec {
    width: 400px;
  }
  .video-bg-btn .pause-icon {
    width: 25px !important;
    height: 25px !important;
  }
  .video-bg-btn .play-icon {
    width: 20px !important;
    height: 20px !important;
  }
  /* .video-bg-btn button {
    width: 88px !important;
    height: 42px !important;
  } */
}
@media (min-width: 568px) and (max-width: 767px) {
  .head-section .head-sec .carousal-signin-sec button,
  .head-section .head-sec .carousal-signin-sec .dropdown {
    font-size: 16px;
  }
  .head-section .head-sec .carousal-signin-sec {
    width: 500px;
  }
  .video-bg-btn .play-icon {
    width: 20px !important;
    height: 20px !important;
  }
  .video-bg-btn .pause-icon {
    width: 25px !important;
    height: 25px !important;
  }
}
@media (max-width: 380px) {
  .head-section .head-sec .content-section p {
    font-size: 30px;
  }
  .head-section .head-sec .content-section .desc span {
    font-size: 16px;
  }
  /* .head-section .head-sec .view-all-properties button {
    margin-top: 40px;
  } */
  .head-section .head-sec .carousal-signin-sec button,
  .head-section .head-sec .carousal-signin-sec .dropdown {
    font-size: 11px;
  }
  .head-section .head-sec .carousal-signin-sec {
    width: 311px;
  }
  .video-bg-btn .pause-icon {
    width: 20px !important;
    height: 20px !important;
  }
  .video-bg-btn .play-icon {
    width: 15px !important;
    height: 15px !important;
  }
  /* .video-bg-btn button {
    width: 68px !important;
    height: 32px !important;
  } */
  .management_details .profile-details .name {
    font-size: 30px !important;
  }
  .management_details .profile-details .designation {
    font-size: 21px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 15px !important;
  }
}
@media (min-width: 381px) and (max-width: 480px) {
  /* .head-section .head-sec .content-section p {
    font-size: 35px;
  } */
  .head-section .head-sec .content-section .desc span {
    font-size: 18px;
  }
  /* .head-section .head-sec .view-all-properties button {
    margin-top: 40px;
  } */
  .management_details .profile-details .name {
    font-size: 34px !important;
  }
  .management_details .profile-details .designation {
    font-size: 25px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 16px !important;
  }
}
@media (max-width: 480px) {
  .head-section .head-sec .carousal-section .carousal-bg {
    height: 500px;
  }
  .testimonials .carousal_sec {
    padding: 0px 20px !important;
  }

  .featured_properties .header_sec .snd_sec {
    margin-top: 20px !important;
  }
  .testimonials .header_sec h3 {
    font-size: 28px !important;
  }
  .testimonials .header_sec p {
    font-size: 16px !important;
  }
  .custom_video_sec .header_sec h3 {
    font-size: 28px !important;
  }
  .custom_video_sec .header_sec p {
    font-size: 16px !important;
  }

  .testimonials .carousal_sec p {
    font-size: 14px !important;
  }
  .testimonials .carousal_sec .sections {
    padding: 15px !important;
  }
  .testimonials .carousal_sec .slick-next {
    width: 30px !important;
    height: 30px !important;
    right: -35px !important;
  }
  .testimonials .carousal_sec .slick-next:before {
    width: 20px !important;
    height: 20px !important;
  }
  .testimonials .carousal_sec .slick-prev {
    width: 30px !important;
    height: 30px !important;
    left: -35px !important;
  }
  .testimonials .carousal_sec .slick-prev:before {
    width: 20px !important;
    height: 20px !important;
  }
  .testimonials .carousal_sec h4 {
    font-size: 23px !important;
  }

  .head-section .head-sec .content-section p {
    font-size: 31px !important;
  }
  .head-section .head-sec .view-all-properties button {
    font-size: 14px !important;
    padding: 15px 20px !important;
    margin-top: 40px !important;
  }
  .head-section {
    height: 400px !important;
    background-size: cover !important;
  }

  .filter .where_to_sec h3 {
    font-size: 25px !important;
  }
  .filter .where_to_sec p {
    font-size: 16px !important;
  }
  .filter {
    padding: 50px 0px 0px 0px;
  }
  .filter .filter-section .stay p {
    font-size: 14px !important;
  }
  .filter .cmn_txt {
    font-size: 16px !important;
  }
  .filter .cmn_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .filter .right_arrow_logo {
    width: 20px !important;
    height: 20px !important;
    /* margin-left: 3px !important; */
    position: relative;
    left: 5px;
  }
  .filter .ryt_arrow {
    display: none !important;
  }
  .custom_arri_depar_picker {
    min-width: 100% !important;
  }
  .search_detail_logo {
    width: unset;
    min-width: 60px;
    min-height: 50px;
  }
  .search_detail_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .disabled_statedate_false {
    padding: 0px !important;
  }
  .filter .filter-section .search-btn button {
    margin-right: 0px !important;
    font-size: 15px !important;
  }
  .filter .search-btn {
    min-width: 100%;
    padding-right: 10px !important;
    text-align: end;
  }

  .filter .logo {
    width: 80px !important;
  }
  .video-bg-btn button {
    width: 50px !important;
    height: 50px !important;
  }

  .featured_properties .header_sec h3 {
    font-size: 26px !important;
  }
  .featured_properties .header_sec p {
    font-size: 16px !important;
  }
  .featured_properties .view-all-properties button {
    font-size: 13px !important;
  }
  .featured_properties .header_sec {
    flex-direction: column !important;
    align-items: start !important;
  }
  .card_ftr_sec {
    flex-wrap: wrap !important;
    gap: 10px;
  }
  .new_navbar .logo_txt_wht {
    display: none !important;
  }
  .new_navbar .logo_txt_blk {
    display: none !important;
  }
  .content-section .sub_head {
    font-size: 28px !important;
  }

  .member-price {
    font-size: 15px !important;
  }
  .member-price span {
    font-size: 14px !important;
  }
  .non-member-price {
    font-size: 13px !important;
  }
  .non-member-price span {
    font-size: 11px !important;
  }

  .testimonials .carousal_sec .slick-initialized .slick-slide > :first-child {
    padding: 15px;
    margin: 15px;
    border-radius: 20px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .testimonials .carousal_sec .sections {
    border-radius: unset !important;
    background: unset !important;
    box-shadow: unset !important;
    padding: unset !important;
  }
  .about_us .filter {
    padding: 30px 0px 0px 0px !important;
  }
}
/* @media (min-width: 481px) and (max-width: 575px) {
  .management_details .profile-logo {
    height: 430px !important;
  }
  .management_details .profile-logo img {
    object-fit: cover !important;
  }
} */
@media (min-width: 576px) and (max-width: 767px) {
}
@media (min-width: 481px) and (max-width: 767px) {
  .head-section .head-sec .content-section p {
    font-size: 40px;
  }
  .head-section .head-sec .view-all-properties button {
    font-size: 19px !important;
    padding: 15px 20px !important;
  }
  .head-section {
    height: 450px !important;
    background-size: cover !important;
  }

  .head-section .head-sec .content-section .desc span {
    font-size: 18px;
  }
  .head-section .head-sec .view-all-properties button {
    margin-top: 40px;
  }
  .head-section .head-sec .carousal-section .carousal-bg {
    height: 600px;
  }

  .testimonials .carousal_sec {
    padding: 0px 40px !important;
  }
  .testimonials .header_sec h3 {
    font-size: 30px !important;
  }
  .testimonials .header_sec p {
    font-size: 16px !important;
  }

  .testimonials .carousal_sec p {
    font-size: 14px !important;
  }
  .testimonials .carousal_sec .sections {
    padding: 15px !important;
  }
  .testimonials .carousal_sec .slick-next {
    width: 40px !important;
    height: 40px !important;
    right: -55px !important;
  }
  .testimonials .carousal_sec .slick-next:before {
    width: 25px !important;
    height: 25px !important;
  }
  .testimonials .carousal_sec .slick-prev {
    width: 40px !important;
    height: 40px !important;
    left: -55px !important;
  }
  .testimonials .carousal_sec .slick-prev:before {
    width: 25px !important;
    height: 25px !important;
  }
  .testimonials .carousal_sec h4 {
    font-size: 23px !important;
  }

  .filter .where_to_sec h3 {
    font-size: 32px !important;
  }
  .filter .where_to_sec p {
    font-size: 16px !important;
  }
  .filter {
    padding: 50px 0px 0px 0px;
  }
  .filter .filter-section .stay p {
    font-size: 17px !important;
  }
  .filter .cmn_txt {
    font-size: 16px !important;
  }
  .filter .cmn_logo {
    width: 23px !important;
    height: 23px !important;
  }
  .filter .right_arrow_logo {
    width: 25px !important;
    height: 25px !important;
    margin-left: 3px !important;
  }
  .filter .ryt_arrow {
    display: none !important;
  }
  .custom_arri_depar_picker {
    min-width: 100% !important;
  }

  .disabled_statedate_false {
    padding: 0px !important;
  }
  .filter .filter-section .search-btn button {
    margin-right: 0px !important;
    font-size: 15px !important;
  }
  .filter .search-btn {
    min-width: 100%;
    /* padding-right: 10px !important; */
    text-align: end;
  }
  .filter .filter-section .filter-all-categories {
    flex-wrap: wrap !important;
    padding: 10px 10px !important;
  }
  .filter .logo {
    width: 80px !important;
  }

  .featured_properties .header_sec h3 {
    font-size: 30px !important;
  }
  .featured_properties .header_sec p {
    font-size: 16px !important;
  }
  .featured_properties .view-all-properties button {
    font-size: 15px !important;
  }
  .featured_properties .header_sec {
    flex-direction: column !important;
    align-items: start !important;
  }
  .featured_properties .header_sec .snd_sec {
    margin-top: 20px !important;
  }

  .custom_video_sec .header_sec h3 {
    font-size: 30px !important;
  }
  .custom_video_sec .header_sec p {
    font-size: 16px !important;
  }
  .card_ftr_sec {
    flex-wrap: wrap !important;
    gap: 10px;
  }
  .where-cont .txtgray {
    font-size: 14px !important;
  }
  .where-cont .themeclr {
    font-size: 16px !important;
  }
  .where-cont .rent {
    font-size: 18px !important;
  }
  .new_navbar .logo_txt_wht {
    font-size: 35px !important;
  }
  .new_navbar .logo_txt_blk {
    font-size: 30px !important;
  }
  .content-section .sub_head {
    font-size: 32px !important;
  }

  .member-price {
    font-size: 16px !important;
  }
  .member-price span {
    font-size: 13px !important;
  }
  .non-member-price {
    font-size: 13px !important;
  }
  .non-member-price span {
    font-size: 11px !important;
  }

  .testimonials .carousal_sec .slick-initialized .slick-slide > :first-child {
    padding: 15px;
    margin: 15px;
    border-radius: 20px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .testimonials .carousal_sec .sections {
    border-radius: unset !important;
    background: unset !important;
    box-shadow: unset !important;
    padding: unset !important;
  }

  .video-bg-btn button {
    width: 60px !important;
    height: 60px !important;
  }
  /* .management_details .profile-details .name {
    font-size: 34px !important;
  }
  .management_details .profile-details .designation {
    font-size: 25px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 16px !important;
  } */
  .management_details .profile-details .name {
    font-size: 39px !important;
  }
  .management_details .profile-details .designation {
    font-size: 27px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 17px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .testimonials .carousal_sec {
    padding: 0px 40px !important;
  }
  .testimonials .header_sec h3 {
    font-size: 30px !important;
  }
  .testimonials .header_sec p {
    font-size: 16px !important;
  }

  .testimonials .carousal_sec p {
    font-size: 14px !important;
  }
  .testimonials .carousal_sec .sections {
    padding: 20px !important;
  }
  .testimonials .carousal_sec .slick-next {
    width: 40px !important;
    height: 40px !important;
    right: -55px !important;
  }
  .testimonials .carousal_sec .slick-next:before {
    width: 25px !important;
    height: 25px !important;
  }
  .testimonials .carousal_sec .slick-prev {
    width: 40px !important;
    height: 40px !important;
    left: -55px !important;
  }
  .testimonials .carousal_sec .slick-prev:before {
    width: 25px !important;
    height: 25px !important;
  }
  .testimonials .carousal_sec h4 {
    font-size: 23px !important;
  }

  .head-section .head-sec .content-section p {
    font-size: 40px;
  }
  .head-section .head-sec .view-all-properties button {
    font-size: 19px !important;
    padding: 15px 20px !important;
  }
  .head-section {
    height: 550px !important;
    background-size: cover !important;
  }
  .head-section .head-sec .view-all-properties button {
    margin-top: 60px !important;
  }

  .filter .cmn_txt {
    font-size: 16px !important;
  }
  .filter .cmn_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .filter .right_arrow_logo {
    width: 20px !important;
    height: 20px !important;
    margin-left: 3px !important;
  }
  .filter .filter-section .search-btn button {
    font-size: 16px !important;
  }
  .filter .logo {
    width: 100px !important;
  }
  .search_detail_logo {
    width: unset !important;
    height: 75px !important;
  }
  .search_detail_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .filter .where_to_sec h3 {
    font-size: 32px !important;
  }
  .filter .where_to_sec p {
    font-size: 16px !important;
  }

  .featured_properties .header_sec h3 {
    font-size: 30px !important;
  }
  .featured_properties .header_sec p {
    font-size: 16px !important;
  }
  .featured_properties .view-all-properties button {
    font-size: 15px !important;
  }
  .featured_properties .header_sec {
    flex-direction: column !important;
    align-items: start !important;
  }
  .featured_properties .header_sec .snd_sec {
    margin-top: 20px !important;
  }

  .custom_video_sec .header_sec h3 {
    font-size: 30px !important;
  }
  .custom_video_sec .header_sec p {
    font-size: 16px !important;
  }
  .card_ftr_sec {
    flex-wrap: wrap !important;
    gap: 10px;
  }
  .filter .filter-section .filter-all-categories {
    flex-wrap: wrap;
  }
  .content-section .sub_head {
    font-size: 32px !important;
  }

  .member-price {
    font-size: 16px !important;
  }
  .member-price span {
    font-size: 14px !important;
  }
  .non-member-price {
    font-size: 13px !important;
  }
  .non-member-price span {
    font-size: 11px !important;
  }
  .testimonials .carousal_sec .slick-initialized .slick-slide > :first-child {
    padding: 15px;
    margin: 15px;
    border-radius: 20px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .testimonials .carousal_sec .sections {
    border-radius: unset !important;
    background: unset !important;
    box-shadow: unset !important;
    padding: unset !important;
  }
  .management_details .profile-details .name {
    font-size: 35px !important;
  }
  .management_details .profile-details .designation {
    font-size: 23px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 16px !important;
  }
  .management_details .profile-logo {
    height: 400px !important;
  }
  .management_details .profile-logo img {
    object-fit: fill !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .testimonials .carousal_sec {
    padding: 0px 40px !important;
  }
  .testimonials .header_sec h3 {
    font-size: 30px !important;
  }
  .testimonials .header_sec p {
    font-size: 16px !important;
  }

  .testimonials .carousal_sec p {
    font-size: 14px !important;
  }
  .testimonials .carousal_sec .sections {
    padding: 21px !important;
  }
  .testimonials .carousal_sec .slick-next {
    width: 40px !important;
    height: 40px !important;
    right: -55px !important;
  }
  .testimonials .carousal_sec .slick-next:before {
    width: 25px !important;
    height: 25px !important;
  }
  .testimonials .carousal_sec .slick-prev {
    width: 40px !important;
    height: 40px !important;
    left: -55px !important;
  }
  .testimonials .carousal_sec .slick-prev:before {
    width: 25px !important;
    height: 25px !important;
  }
  .testimonials .carousal_sec h4 {
    font-size: 23px !important;
  }

  .head-section .head-sec .content-section p {
    font-size: 40px;
  }
  .head-section .head-sec .view-all-properties button {
    font-size: 19px !important;
    padding: 15px 20px !important;
  }
  .head-section {
    height: 600px !important;
    /* background-size: cover !important; */
  }
  .head-section .head-sec .view-all-properties button {
    margin-top: 60px !important;
  }

  .filter .cmn_txt {
    font-size: 16px !important;
  }
  .filter .cmn_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .filter .right_arrow_logo {
    width: 20px !important;
    height: 20px !important;
    margin-left: 3px !important;
  }
  .filter .filter-section .search-btn button {
    font-size: 16px !important;
  }

  .filter .where_to_sec h3 {
    font-size: 30px !important;
  }
  .filter .where_to_sec p {
    font-size: 16px !important;
  }

  .featured_properties .header_sec h3 {
    font-size: 30px !important;
  }
  .featured_properties .header_sec p {
    font-size: 16px !important;
  }
  .featured_properties .view-all-properties button {
    font-size: 15px !important;
  }
  .featured_properties .header_sec {
    flex-direction: column !important;
    align-items: start !important;
  }
  .featured_properties .header_sec .snd_sec {
    margin-top: 20px !important;
  }
  .search_detail_logo {
    width: unset;
    height: 75px;
  }
  .search_detail_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .custom_video_sec .header_sec h3 {
    font-size: 30px !important;
  }
  .custom_video_sec .header_sec p {
    font-size: 16px !important;
  }
  .card_ftr_sec {
    flex-wrap: wrap !important;
    gap: 10px;
  }

  .new_navbar .logo_txt_wht {
    font-size: 30px !important;
  }
  .new_navbar .logo_txt_blk {
    font-size: 30px !important;
  }
  .nav_header_ryt_sec .btn-primary {
    font-size: 20px !important;
  }
  .nav_header_ryt_sec .login_btn {
    font-size: 16px !important;
    padding: 9px 35px !important;
  }
  .content-section .sub_head {
    font-size: 34px !important;
  }
  .member-price {
    font-size: 16px !important;
  }
  .member-price span {
    font-size: 14px !important;
  }
  .non-member-price {
    font-size: 13px !important;
  }
  .non-member-price span {
    font-size: 12px !important;
  }
  .video-bg-btn button {
    width: 75px !important;
    height: 75px !important;
  }
  .video-bg-btn .play-icon {
    width: 25px !important;
    height: 25px !important;
  }
  .video-bg-btn .pause-icon {
    width: 33px !important;
    height: 33px !important;
  }
  .management_details .profile-details .name {
    font-size: 35px !important;
  }
  .management_details .profile-details .designation {
    font-size: 23px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 16px !important;
  }
  .management_details .profile-logo {
    height: 460px !important;
  }
  .management_details .profile-logo img {
    object-fit: fill !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .testimonials .carousal_sec {
    padding: 0px 50px !important;
  }
  .testimonials .header_sec h3 {
    font-size: 30px !important;
  }
  .testimonials .header_sec p {
    font-size: 16px !important;
  }

  .testimonials .carousal_sec p {
    font-size: 15px !important;
  }
  .testimonials .carousal_sec .sections {
    padding: 21px !important;
  }
  .testimonials .carousal_sec .slick-next {
    width: 50px !important;
    height: 50px !important;
    right: -70px !important;
  }
  .testimonials .carousal_sec .slick-next:before {
    width: 30px !important;
    height: 30px !important;
  }
  .testimonials .carousal_sec .slick-prev {
    width: 50px !important;
    height: 50px !important;
    left: -70px !important;
  }
  .testimonials .carousal_sec .slick-prev:before {
    width: 30px !important;
    height: 30px !important;
  }
  .testimonials .carousal_sec h4 {
    font-size: 23px !important;
  }

  .head-section .head-sec .content-section p {
    font-size: 41px;
  }
  .head-section .head-sec .view-all-properties button {
    font-size: 17px !important;
    padding: 12px 30px !important;
    margin-top: 60px !important;
  }
  .head-section {
    height: 600px !important;
  }
  .filter .cmn_txt {
    font-size: 18px !important;
  }
  .filter .cmn_logo {
    width: 26px !important;
    height: 26px !important;
    margin-right: 15px !important;
  }
  .arrival-calender_img {
    top: -4px !important;
  }
  .filter .right_arrow_logo {
    width: 25px !important;
    height: 25px !important;
    margin-left: 3px !important;
  }
  .filter .filter-section .search-btn button {
    font-size: 16px !important;
  }

  .filter .where_to_sec h3 {
    font-size: 30px !important;
  }
  .filter .where_to_sec p {
    font-size: 16px !important;
  }
  .search_detail_logo {
    width: unset;
    height: 75px;
  }
  .search_detail_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .featured_properties .header_sec h3 {
    font-size: 30px !important;
  }
  .featured_properties .header_sec p {
    font-size: 16px !important;
  }
  .featured_properties .view-all-properties button {
    font-size: 15px !important;
  }
  .featured_properties .header_sec {
    /* flex-direction: column !important;
    align-items: start !important; */
  }
  .featured_properties .header_sec .snd_sec {
    /* margin-top: 20px !important; */
  }
  .custom_video_sec .header_sec h3 {
    font-size: 30px !important;
  }
  .custom_video_sec .header_sec p {
    font-size: 16px !important;
  }
  .card_ftr_sec {
    flex-wrap: wrap !important;
    gap: 7px;
  }
  .new_navbar .logo_txt_blk {
    font-size: 35px !important;
  }
  .new_navbar .logo_txt_wht {
    font-size: 40px !important;
  }
  .nav_header_ryt_sec .blk_clr {
    font-size: 17px !important;
  }
  .nav_header_ryt_sec .wht_clr {
    font-size: 17px !important;
  }
  .nav_header_ryt_sec .login_btn {
    padding: 8px 35px !important;
    font-size: 15px !important;
  }
  .content-section .sub_head {
    font-size: 38px !important;
  }

  .member-price {
    font-size: 16px !important;
  }
  .member-price span {
    font-size: 14px !important;
  }
  .non-member-price {
    font-size: 13px !important;
  }
  .non-member-price span {
    font-size: 11px !important;
  }
  .footer-section .content-des {
    font-size: 17px !important;
  }
  .nav_header_ryt_sec .dropdown-toggle::after {
    vertical-align: unset !important;
    border-top: 0.6em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }
  .video-bg-btn button {
    width: 85px !important;
    height: 85px !important;
  }
  .video-bg-btn .play-icon {
    width: 30px !important;
    height: 30px !important;
  }
  .video-bg-btn .pause-icon {
    width: 38px !important;
    height: 38px !important;
  }
  .management_details .profile-details .name {
    font-size: 40px !important;
  }
  .management_details .profile-details .designation {
    font-size: 26px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 21px !important;
  }
  .management_details .profile-logo {
    height: 600px !important;
  }
  .management_details .profile-logo img {
    object-fit: fill !important;
  }
}

@media (min-width: 1400px) and (max-width: 1800px) {
  .testimonials .carousal_sec {
    padding: 0px 60px !important;
  }
  .testimonials .header_sec h3 {
    font-size: 45px !important;
  }
  .testimonials .header_sec p {
    font-size: 16px !important;
  }

  .testimonials .carousal_sec p {
    font-size: 15px !important;
  }
  .testimonials .carousal_sec .sections {
    padding: 21px !important;
  }
  .testimonials .carousal_sec .slick-next {
    width: 50px !important;
    height: 50px !important;
    right: -70px !important;
  }
  .testimonials .carousal_sec .slick-next:before {
    width: 30px !important;
    height: 30px !important;
  }
  .testimonials .carousal_sec .slick-prev {
    width: 50px !important;
    height: 50px !important;
    left: -70px !important;
  }
  .testimonials .carousal_sec .slick-prev:before {
    width: 30px !important;
    height: 30px !important;
  }
  .testimonials .carousal_sec h4 {
    font-size: 23px !important;
  }

  .head-section .head-sec .content-section p {
    font-size: 45px !important;
  }
  .head-section .head-sec .view-all-properties button {
    font-size: 19px !important;
    padding: 15px 20px !important;
  }
  .head-section {
    height: 6000px !important;
  }
  .head-section .head-sec .view-all-properties button {
    margin-top: 60px !important;
  }
  .filter .cmn_txt {
    font-size: 20px !important;
  }
  .filter .cmn_logo {
    width: 26px !important;
    height: 26px !important;
    margin-right: 15px !important;
  }
  .arrival-calender_img {
    top: -4px !important;
  }
  .filter .right_arrow_logo {
    width: 25px !important;
    height: 25px !important;
    margin-left: 3px !important;
  }
  .filter .filter-section .search-btn button {
    font-size: 16px !important;
  }

  .featured_properties .header_sec h3 {
    font-size: 30px !important;
  }
  .featured_properties .header_sec p {
    font-size: 16px !important;
  }
  .featured_properties .view-all-properties button {
    font-size: 15px !important;
  }
  .custom_video_sec .header_sec h3 {
    font-size: 45px !important;
  }
  .custom_video_sec .header_sec p {
    font-size: 16px !important;
  }
  .card_ftr_sec {
    flex-wrap: wrap !important;
    gap: 10px;
  }
  .nav_header_ryt_sec .dropdown-toggle::after {
    vertical-align: unset !important;
    border-top: 0.6em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }
  .management_details .profile-details .name {
    font-size: 45px !important;
  }
  .management_details .profile-details .designation {
    font-size: 28px !important;
  }
  .management_details .profile-details .description,
  .management_details .profile-details .mobile-no,
  .management_details .profile-details .email {
    font-size: 23px !important;
  }
  .management_details .profile-logo {
    height: 600px !important;
  }
  .management_details .profile-logo img {
    object-fit: contain !important;
    width: unset !important;
  }
}
@media (min-width: 1801px) {
  .head-section {
    height: 650px !important;
  }
}

@media (min-width: 1400px) {
  .member-price {
    font-size: 18px !important;
  }
  .member-price span {
    font-size: 16px !important;
  }
  .non-member-price {
    font-size: 13px !important;
  }
  .non-member-price span {
    font-size: 11px !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .search-btn.custom-width-collapse {
    width: 100%;
    text-align: end;
  }
}

@media (max-width: 767px) {
  .place-questions {
    margin-top: 100px !important;
  }
  .headercont {
    margin-top: 0px !important;
  }
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .management_details .custom-row {
    width: 100%;
    display: flex;
    /* gap: 3%; */
  }
  .management_details .custom-row .custom-col1 {
    width: 42%;
  }
  .management_details .custom-row .custom-col2 {
    width: 55%;
  }
  .about_us .custom-row:nth-child(odd) {
    display: flex;
    flex-direction: row;
  }
  .about_us .custom-row:nth-child(even) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

/* @media (max-width: 400px) {
  .featured_properties .header_sec h3 {
    font-size: 23px !important;
  }
} */
.play-icon {
  position: absolute;
  align-items: center;
  top: 52%;
  left: 52%;
  transform: translate(-50%, -50%);
}
.video-bg-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-bg-btn button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0.5;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  outline: none;
  opacity: 1;
}

.video-bg-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-bg-btn button svg {
  color: var(--dark-blue) !important;
}
.video-bg-btn .pause-icon {
  width: 45px;
  height: 45px;
}
.video-bg-btn .play-icon {
  width: 35px;
  height: 35px;
}

@media (max-width: 520px) {
  .head-section .head-sec .carousel-indicators {
    right: 10rem;
    bottom: 4.35rem;
  }
  .filter .filter-section .filter-all-categories {
    flex-wrap: wrap;
    display: flex;
  }
  .filter .filter-section {
    height: auto;
    padding: 20px 0px;
  }
}

/* @media (max-width: 567px) {
  .filter .filter-section .filter-all-categories{
    flex-wrap: wrap;
    display: flex;
  }
} */

.arrival-sec {
  padding-left: 1rem;
}

@media (min-width: 521px) and (max-width: 767px) {
  .filter-section-location {
    width: 18%;
  }
  .filter-section-dec-wid {
    /* width: 20%; */
  }
  .filter .filter-section .search-btn button {
    /* width: 100%; */
  }
  .col_card_in {
    padding: 10px 4px;
  }
  .filter .filter-section .location input,
  .filter .filter-section .departure-sec input {
    /* width: 84px; */
  }
  .arrival-sec {
    padding-left: 0.5rem;
  }
  /* .filter-search-button{
    background: #1469e2;
  } */
}
.filter-search-button {
  /* background: #1469e2; */
  text-align: center;
}
.success-box.suc_box_blue {
  background-color: #def5fa !important;
  background: #def5fa !important;
  color: #607ebb !important;
}
.rightcont.rightcont_new {
  height: unset !important;
}

.departure-sec-custom {
  /* padding-left: 100px; */
}

.disabled_statedate_true {
  /* pointer-events: none;
  opacity: 0.5; */
  background-color: white;
  border-radius: 20px;
  padding: 5px 10px;
}
.disabled_statedate_false {
  padding: 5px 10px 5px 0px;
}

.dd_dropdown_card.show .disabled_statedate_true {
  background-color: white !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}
.dd_dropdown_card .disabled_statedate_true {
  background-color: transparent !important;
}

.departure_custom_input_color {
  color: #082e54;
  cursor: pointer;
}

@media (max-width: 480px) {
  .departure-sec-custom {
    /* padding-left: 0px !important; */
  }
  .landing_page .location_custom_mbl {
    /* position: absolute; */
    /* top: 30px; */
  }
  .landing_page .react-datepicker {
    display: unset !important;
  }
  /* .custom_date_picker_sec {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
  } */
}

@media (min-width: 481px) and (max-width: 767px) {
  .departure-sec-custom {
    /* padding-left: 10px !important; */
  }
}

.arrival-calender_img {
  position: relative;
  top: -2px;
}

@media (max-width: 350px) {
  .dd_menu_shadow_card.country_card {
    left: 0% !important;
  }
  .modal-content .dd_menu_shadow_card.country_card {
    left: -16% !important;
  }
  .filter-map .dd_menu_shadow_card.country_card {
    left: -10% !important;
  }
}
@media (min-width: 351px) and (max-width: 500px) {
  .dd_menu_shadow_card.country_card {
    left: 18% !important;
  }
}
@media (min-width: 576px) and (max-width: 580px) {
  .dd_menu_shadow_card.country_card {
    /* left: -12% !important; */
  }
  .filter-map .dd_menu_shadow_card.country_card {
    left: -10% !important;
  }
}
@media (min-width: 760px) and (max-width: 992px) {
  .departure-sec-custom {
    /* padding-left: 50px !important; */
  }
  .experience .departure-sec-custom {
    /* padding-left: 100px !important; */
  }
}

.room_detail_date_picker {
  /* padding-bottom: 10px !important; */
}

.room_detail_date_picker .disabled_statedate_true {
  /* padding: 0px 10px ; */
  /* margin-bottom: 5px; */
  cursor: pointer;
}
.room_detail_date_picker .disabled_statedate_false {
  padding: 0px 7px !important;
  /* margin-bottom: 5px; */
  cursor: pointer;
  /* padding: 0px; */
}

.room_detail_date_picker input[type="text"] {
  cursor: pointer;
}

.room_detail .accordion-button {
  /* padding: 1rem 1.9rem !important; */
  padding: 1rem 1rem !important;
}

.filter_date_picker_cutom {
  top: 0px !important;
  bottom: auto !important;
  transform: translate(0px, 80px) !important;
}

@media only screen and (min-width: 760px) and (max-width: 992px) {
  .dd_menu_shadow_card.country_card {
    /* left: -20% !important; */
  }
}

/* sakthi 10-01-24 */
.head-section {
  /* background: url("../images/landingPage/header_bg.png") no-repeat; */
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  width: 100%;
  /* height: 700px; */
  display: flex;
  align-items: center;

  /* display: flex;
  flex-direction: column; */

  /* background-size: cover;
  background-position: center;
  height: 700px;  */
  /* background-color: rgb(0,0,0,35%); */
  /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35); */
}

.filter .cmn_logo {
  width: 27px;
  height: 27px;
  object-fit: contain;
  margin-right: 10px;
}
.filter .right_arrow_logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.custom_arri_depar_picker {
  min-width: 38%;
}
.custom_arri_depar_picker .btn_trans_icon {
  width: 100%;
}
.custom_arri_depar_picker .custom_date_picker_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: auto;
}
.cmn_date_picker_cursor {
  cursor: pointer;
}

.filter .cmn_txt {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
}
.departure-sec input {
  width: 150px !important;
}
.custom_arri_depar_picker_exp {
  /* min-width: 45% !important; */
}
.filter .filter-section .stay-rental-exp .stay-bg {
  border-bottom: 2px solid var(--dark-blue);
}

.filter .ryt_arrow {
  height: 50px;
  width: 2px;
}

.custom_video_sec .header_sec h3 {
  color: #000;
  font-family: Inter;
  font-size: 50px;
  font-weight: 700;
}
.custom_video_sec .header_sec p {
  color: #000;
  font-family: Inter;
  font-size: 19px;
  font-weight: 500;
}
.custom_video_sec video {
  border-radius: 12px;
}

.featured_properties .header_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featured_properties .header_sec h3 {
  color: #000;
  font-family: Inter;
  font-size: 45px;
  font-weight: 700;
}
.featured_properties .header_sec p {
  color: #000;
  font-family: Inter;
  font-size: 19px;
  font-weight: 500;
}

.featured_properties .view-all-properties button {
  padding: 13px 19px;
  border-radius: 9px;
  text-align: center;
  font-size: 19px;
  font-weight: 700;
  line-height: normal;
}

.featured_properties .custom_scroll {
  overflow: unset !important;
}
.featured_properties .cus_card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
}

.cus_card .where-card .fa-heart,
.cus_card .where-card .fa-heart-o {
  right: 15px !important;
  left: unset !important;
  color: var(--dark-blue);
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  font-size: 21px;
}
.cus_card .where-card .fa-heart::before,
.cus_card .where-card .fa-heart-o::before {
  bottom: -2px;
  position: relative;
}
.cus_card .where-card .fa-heart-o::before {
  font-family: "FontAwesome";
  font-weight: 800;
}
.cus_card .where-card .fa-heart::before {
  font-size: 22px !important;
}
.card_ftr_sec span {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}
.card_ftr_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_ftr_sec .cmn_logo {
  width: 20px;
}
.where-cont .themeclr {
  color: #000 !important;
  font-family: Inter;
  font-size: 19px;
  font-weight: 700;
}
.where-cont .txtgray {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}
.where-cont .rent {
  font-size: 20px !important;
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  color: var(--dark-blue) !important;
}

.testimonials .header_sec h3 {
  color: #000;
  font-family: Inter;
  font-size: 55px;
  font-weight: 700;
}
.testimonials .header_sec p {
  color: #000;
  font-family: Inter;
  font-size: 19px;
  font-weight: 500;
}
.testimonials .carousal_sec {
  padding: 0px 80px;
}
.testimonials .carousal_sec p {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.testimonials .carousal_sec h4 {
  color: #000;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.testimonials .carousal_sec .slick-next {
  background-color: var(--dark-blue) !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
}
.testimonials .carousal_sec .slick-next:before {
  content: "" !important;
  background: url("../images/landingPage/testimonials/right_arrow.png")
    no-repeat;
  background-size: 100% 100%;
  width: 35px;
  height: 35px;
  display: inline-block;
  opacity: 1 !important;
}

.testimonials .carousal_sec .slick-next {
  right: -80px;
}

.testimonials .carousal_sec .slick-prev {
  background-color: var(--dark-blue) !important;
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
}
.testimonials .carousal_sec .slick-prev:before {
  content: "" !important;
  background: url("../images/landingPage/testimonials/left_arrow.png") no-repeat;
  background-size: 100% 100%;
  width: 35px;
  height: 35px;
  display: inline-block;
  opacity: 1 !important;
}

.testimonials .carousal_sec .slick-prev {
  left: -80px;
}

.new_navbar {
  /* padding-top: 40px !important; */
  position: fixed;
  /* padding-top: 20px; */
  width: 100%;
  z-index: 1000 !important;
}

.nav_header_ryt_sec {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav_header_ryt_sec .login_btn {
  padding: 8px 35px;
  border-radius: 7px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.lite-bg-image {
  /* margin-top: 100px; */
}

@media (max-width: 991px) {
  .new_navbar .navbar-light .navbar-toggler {
    background-color: white !important;
    border-radius: 5px !important;
    box-shadow: none !important;
  }
  .nav_header_ryt_sec {
    flex-direction: column !important;
  }

  .new_navbar .navbar-collapse {
    /* background-color: white; */
    /* border-radius: 8px; */
    height: 204px !important;
    overflow-y: auto;
    border-top: 1px solid #f1ecec !important;
  }

  /* .new_navbar .navbar_default_bg {
    background-color: white;
  }
  .nav_header_ryt_sec .wht_clr {
    color: black;
  } */
  .new_navbar .logo_txt_blk {
    font-size: 30px !important;
  }
  .nav_header_ryt_sec .blk_clr {
    font-size: 17px !important;
  }
  .nav_header_ryt_sec .login_btn {
    font-size: 15px !important;
  }

  .cmn_mobile_view {
    display: block;
  }
  .cmn_web_view {
    display: none;
  }
}
@media (min-width: 992px) {
  .cmn_mobile_view {
    display: none;
  }
  .cmn_web_view {
    display: block;
  }
}

.new_navbar .navbar_wht_bg {
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.nav_header_ryt_sec .wht_clr {
  background-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  color: #fff;
  font-family: Inter;
  font-size: 19px;
  font-weight: 700;
}
.login_btn::after {
  display: none;
}
.nav_header_ryt_sec .blk_clr {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  color: #fff;
  font-family: Inter;
  font-size: 19px;
  font-weight: 700;
}
.new_navbar .logo_txt_wht {
  color: #fff;
  font-family: Inter;
  font-size: 40px;
  font-weight: 800;
}
.new_navbar .logo_txt_blk {
  color: black;
  font-family: Inter;
  font-size: 35px;
  font-weight: 700;
}

.new_navbar .navbar-nav .dropdown-menu {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.cus_card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
}
.guest_custom_drop .dd_menu_shadow_card {
  /* left: -20% !important; */
}

@media (max-width: 420px) {
  .guest_custom_drop .dd_menu_shadow_card {
    left: -10px !important;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .location_custom_drop .dd_menu_shadow_card {
    /* left: -30px !important; */
  }
}

.content-section .sub_head {
  color: #fff;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.member-price {
  color: var(--dark-blue);
  font-weight: 700;
}

.non-member-price {
  color: #747474;
  font-weight: 400;
}

.testimonials .carousal_sec .sections {
  border-radius: 20px;
  background: var(--Neutral-100, #fff);
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
}

.expdetails .react-datepicker__day--disabled:hover,
.expdetails .react-datepicker__month-text--disabled:hover,
.expdetails .react-datepicker__quarter-text--disabled:hover,
.expdetails .react-datepicker__year-text--disabled:hover {
  background-color: transparent !important;
  color: #ccc !important;
  cursor: not-allowed;
}

.expdetails .react-datepicker__day--holidays,
.expdetails .react-datepicker__month-text--holidays,
.expdetails .react-datepicker__quarter-text--holidays,
.expdetails .react-datepicker__year-text--holidays {
  background-color: transparent !important;
  /* color: black; */
}

.expdetails .react-datepicker__day--holidays:hover,
.expdetails .react-datepicker__month-text--holidays:hover,
.expdetails .react-datepicker__quarter-text--holidays:hover,
.expdetails .react-datepicker__year-text--holidays:hover {
  background-color: #f8f9fa;
  color: black;
}

.experience_calendar .holiday-overlay {
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2),
    0px 0px 7px 0 rgba(0, 0, 0, 0.19);
  font-size: 13px;
  padding: 5px 15px;
}

/* .expdetails .react-datepicker__day--disabled:hover .custom_date_tool,
.expdetails .react-datepicker__month-text--disabled:hover .custom_date_tool,
.expdetails .react-datepicker__quarter-text--disabled:hover .custom_date_tool,
.expdetails .react-datepicker__year-text--disabled:hover .custom_date_tool {
  display: block !important;
} */

/* .react-datepicker__day:hover .custom_date_tool {
  background-color: lightblue;
} */

/* .custom_tooltip {
  position: relative;
} */

/* .custom_date_tool {
  background-color: white;
  color: black;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2),
    0px 0px 7px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 15px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  cursor: default;
  font-size: 13px;
  opacity: 0;
} */

.list_detail_edit {
  padding: 9px 10px !important;
  cursor: pointer;
  border-radius: 5px !important;
  background-color: var(--orange);
  color: var(--white);
  font-size: 12px;
}
.list_detail_edit:hover {
  background-color: var(--lite-black-sub-heading);
  color: var(--white);
}

.org_txt {
  color: var(--yellow) !important;
}

.mbl_login_btn .dropdown-menu {
  margin-bottom: 20px;
}

.navbar_default_bg {
  margin-top: 20px;
}

.navbar_custom_logo {
  /* width: 35px; */
  height: 42px;
  object-fit: cover;
}

/* checking */
/* .yellownav .owl-nav .owl-prev span {
  display: none;
}
.yellownav .owl-nav .owl-prev span::before {
  content: "\f004";
} */

@media only screen and (max-width: 575px) {
  .react-datepicker__day--holidays .holiday-overlay {
    left: unset;
    right: -100%;
    transform: translateX(0%);
    max-width: 74px;
    min-width: 74px;

    white-space: normal;
    word-break: break-word;
    padding: 5px !important;
    font-size: 12px !important;
  }
  .about_us .search_detail_logo {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .react-datepicker__day--holidays .holiday-overlay {
    left: unset;
    right: -100%;
    transform: translateX(0%);
    max-width: 90px;
    min-width: 90px;

    white-space: normal;
    word-break: break-word;
    padding: 5px !important;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .react-datepicker__day--holidays .holiday-overlay {
    left: unset;
    right: -100%;
    transform: translateX(0%);
    max-width: 120px;
    min-width: 120px;

    white-space: normal;
    word-break: break-word;
    padding: 5px !important;
    font-size: 13px !important;
  }
}

.react-datepicker__day--holidays .holiday-overlay {
  z-index: 1;
}

/* @media only screen and (min-width:1200px)
{
  .react-datepicker__day--holidays .holiday-overlay
  {
    left: unset;
    right: -80%;
    transform: translateX(0%);
    max-width: 170px;
    min-width: 170px;
   
    white-space: normal;
    word-break: break-word;
    padding: 5px !important;
    font-size: 13px !important;
  }
} */

/* new 19-02-24 */
/* expdetails */
.back_to_lisitng {
  color: var(--themeclr);
  font-size: 18px;
  font-weight: 500;
}
.back_to_lisitng:hover {
  color: black;
}

.expdetails .member_price {
  color: var(--themeclr);
}
.expdetails .loc_Details {
  font-weight: 600;
}
.expdetails .host_addr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.expdetails .logo {
  width: 80px;
  height: 60px;
  min-width: 80px;
  min-height: 60px;
  object-fit: contain;
}
.show-all-img {
  position: relative;
  cursor: pointer;
}
.show-all-img .show_all_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-weight: 600;
  font-size: 22px;
  text-wrap: nowrap;
}
.show-all-img img {
  /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35); */
}
.card_ftr_secc {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  color: var(--black);
  font-weight: 500;
}
.custom_overview_hr {
  border: 1px solid var(--themeclr);
  opacity: 1 !important;
}

.expdetails .room_img {
  width: 35px;
  /* height: 35px; */
  object-fit: cover;
}
.expdetails .room_det {
  display: flex;
  align-items: center;
  gap: 15px;
}
.expdetails .amenities {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.expdetails .amenities .icon {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: var(--themeclr);
}
.expdetails .amenities .type {
  font-size: 17px;
  font-weight: 500;
}
.request-to-book-btn button {
  padding: 10px 0px;
  border-radius: 7px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

/* cms page common styles start*/
@media (max-width: 380px) {
  .cms_cmn_style h1 {
    font-size: 22px;
  }
  .cms_cmn_style h2 {
    font-size: 21px;
  }
  .cms_cmn_style h3 {
    font-size: 20px;
  }
  .cms_cmn_style h4 {
    font-size: 19px;
  }
  .cms_cmn_style h5 {
    font-size: 18px;
  }
  .cms_cmn_style h6 {
    font-size: 18px;
  }
  .cms_cmn_style p {
    font-size: 15px;
  }
  .cms_cmn_style div {
    font-size: 15px;
  }
  .cms_cmn_style span {
    font-size: 15px;
  }
  .cms_cmn_style a {
    font-size: 15px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 15px;
  }
}
@media (min-width: 381px) and (max-width: 480px) {
  .cms_cmn_style h1 {
    font-size: 25px;
  }
  .cms_cmn_style h2 {
    font-size: 24px;
  }
  .cms_cmn_style h3 {
    font-size: 23px;
  }
  .cms_cmn_style h4 {
    font-size: 22px;
  }
  .cms_cmn_style h5 {
    font-size: 21px;
  }
  .cms_cmn_style h6 {
    font-size: 20px;
  }
  .cms_cmn_style p {
    font-size: 15px;
  }
  .cms_cmn_style div {
    font-size: 15px;
  }
  .cms_cmn_style span {
    font-size: 15px;
  }
  .cms_cmn_style a {
    font-size: 15px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 15px;
  }
}
@media (min-width: 481px) and (max-width: 575px) {
  .cms_cmn_style h1 {
    font-size: 28px;
  }
  .cms_cmn_style h2 {
    font-size: 27px;
  }
  .cms_cmn_style h3 {
    font-size: 26px;
  }
  .cms_cmn_style h4 {
    font-size: 25px;
  }
  .cms_cmn_style h5 {
    font-size: 24px;
  }
  .cms_cmn_style h6 {
    font-size: 23px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 16px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .cms_cmn_style h1 {
    font-size: 31px;
  }
  .cms_cmn_style h2 {
    font-size: 30px;
  }
  .cms_cmn_style h3 {
    font-size: 29px;
  }
  .cms_cmn_style h4 {
    font-size: 28px;
  }
  .cms_cmn_style h5 {
    font-size: 27px;
  }
  .cms_cmn_style h6 {
    font-size: 26px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cms_cmn_style h1 {
    font-size: 33px;
  }
  .cms_cmn_style h2 {
    font-size: 32px;
  }
  .cms_cmn_style h3 {
    font-size: 31px;
  }
  .cms_cmn_style h4 {
    font-size: 30px;
  }
  .cms_cmn_style h5 {
    font-size: 29px;
  }
  .cms_cmn_style h6 {
    font-size: 28px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cms_cmn_style h1 {
    font-size: 35px;
  }
  .cms_cmn_style h2 {
    font-size: 34px;
  }
  .cms_cmn_style h3 {
    font-size: 33px;
  }
  .cms_cmn_style h4 {
    font-size: 32px;
  }
  .cms_cmn_style h5 {
    font-size: 31px;
  }
  .cms_cmn_style h6 {
    font-size: 30px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .cms_cmn_style h1 {
    font-size: 37px;
  }
  .cms_cmn_style h2 {
    font-size: 36px;
  }
  .cms_cmn_style h3 {
    font-size: 35px;
  }
  .cms_cmn_style h4 {
    font-size: 34px;
  }
  .cms_cmn_style h5 {
    font-size: 33px;
  }
  .cms_cmn_style h6 {
    font-size: 32px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 16px;
  }
}
@media (min-width: 1400px) {
  .cms_cmn_style h1 {
    font-size: 39px;
  }
  .cms_cmn_style h2 {
    font-size: 38px;
  }
  .cms_cmn_style h3 {
    font-size: 37px;
  }
  .cms_cmn_style h4 {
    font-size: 36px;
  }
  .cms_cmn_style h5 {
    font-size: 35px;
  }
  .cms_cmn_style h6 {
    font-size: 34px;
  }
  .cms_cmn_style p {
    font-size: 16px;
  }
  .cms_cmn_style div {
    font-size: 16px;
  }
  .cms_cmn_style span {
    font-size: 16px;
  }
  .cms_cmn_style a {
    font-size: 16px;
  }
  .cms_cmn_style ul li,
  .cms_cmn_style ol li {
    font-size: 16px;
  }
}
@media (min-width: 0px) {
  .cms_cmn_style img {
    max-width: 100%;
    height: auto;
  }
}
/* cms page common styles end*/

.management_details .profile-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.management_details .profile-details .name {
  color: var(--orange);
  font-size: 64px;
  font-weight: 700;
}
.management_details .profile-details .designation {
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
}
.management_details .profile-details .description,
.management_details .profile-details .mobile-no,
.management_details .profile-details .email {
  color: var(--black);
  font-size: 24px;
  font-weight: 500;
}
.management_details .profile-details .email .org-txt,
.management_details .profile-details .mobile-no .org-txt {
  color: var(--orange) !important;
}
.management_details .custom-row {
  margin-bottom: 70px;
}
.management_details .profile-logo {
  width: 100%;
  height: 600px;
}
.management_details .profile-logo img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
