@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: Inter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stays img {
  width: 16px;
  height: 16px;
}
.stays button {
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 15px;
}
.stays p.sub-title {
  font-weight: 600;
  font-size: 20px;
}
/* .container_custom .mapSection{position:relative;}
.container_custom .mapComponent{
  position: fixed;
  width:100%;
  top: 0;height:100%;} */

@media only screen and (max-width: 991px) {
  .web_re_hide {
    display: none !important;
  }
}

.mapvei_card {
  background-color: #fff;
  padding: 10px;
  font-size: 12px;
  min-width: 75px;
  text-align: center;
  border-radius: 20px;
  font-weight: 600;
  /* position: absolute;
    bottom: 30px; */
}
.mapvei_card h1 {
  font-size: 18px;
  word-break: break-all;
}
.mapvei_card p {
  font-size: 14px;
  word-break: break-all;
}
.map_height_loc {
  width: 100%;
  min-height: 500px;
  height: 500px;
}
.btn_location_ap {
  position: relative;
  z-index: 1;
}

.upphoto_big.upphoto button {
  min-height: 200px;
  max-width: 100% !important;
}

.noti_page_ul_dir li {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
  margin-bottom: 10px;
  border-left: 5px solid #122e53 !important;
}

.noti_page_ul_dir li.readnoti {
  border-left: 5px solid rgba(0, 0, 0, 0.2) !important;
}

.noti_page_ul_dir li.readnoti p {
  color: #ccc !important;
}

.nofification ul li .d-flex {
  align-items: flex-start !important;
}

.nofification ul li.readnoti .btn-link,
.nofification ul li.readnoti .mark_read_lik {
  color: #ccc !important;
}
.mark_read_lik {
  color: #122e53 !important;
  display: block;
  margin-top: 10px;
  font-style: italic;
}

.mark_read_lik:hover {
  color: #000 !important;
}

.owl-carousel.showallimg {
  position: relative !important;
}

.otp_input_box input {
  min-height: 50px;
  min-width: 50px;
  width: 50px;
}
.otp_input_box input:focus,
.otp_input_box input:focus-visible,
.otp_input_box input:focus-within,
.otp_input_box input:active {
  border: 1px solid #c8c8c8;
  outline: 0px !important;
}
.otp_input_box input {
  border: 1px solid #c8c8c8;
  outline: 0px !important;
}

.fomt_inst_book .form-switch .form-check-input {
  border-color: #ec6043;
}

.fomt_inst_book .form-switch .form-check-input:not(:checked):focus {
  background-position: left 2px top 2px;
  background-image: url(./Assets/images/orange_switch.png);
  background-size: 25px 25px !important;
  background-color: #fff !important;
}
.react_tele {
  display: flex;
}
@media only screen and (min-width: 1026px) {
  .col_card_in .inbtn.inbtn_datepick_new {
    max-width: 100% !important;
  }
  .room_detail_date_picker.room_detail_date_picker_width_set {
    min-width: 100% !important;
  }
}

@media only screen and (min-width: 320px) {
  .filter_date_picker_cutom.room_detail_date_picker .react-datepicker__day-name,
  .filter_date_picker_cutom.room_detail_date_picker .react-datepicker__day,
  .filter_date_picker_cutom.room_detail_date_picker
    .react-datepicker__time-name {
    width: 1.2rem !important;
    height: 1.2rem !important;
    border-radius: 0.1rem;
    line-height: 1.2rem !important;
  }
}
